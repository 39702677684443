import React from 'react';
import FordoEditFeature from '../components/feature/FordoEditFeature';
import FordoSaveFeature from '../components/feature/FordoSaveFeature';
import FordoSearchFeature from '../components/feature/FordoSearchFeature';
import NavigateFeatureButton from '../components/feature/NavigateFeatureButton';
import HeroSection from '../components/hero/HeroSection';
import Footer from '../components/navigation/Footer';
import Topbar from '../components/navigation/Topbar';

const LandingPage = (props) => {
    return (
        <>
            <div class="bg-[#F9FBFF] overflow-hidden">
                <div class="2xl:max-w-screen-xl xl:max-w-screen-lg lg:mx-auto mx-3">
                    <Topbar />
                    <HeroSection />
                </div>
            </div>
            <div class="overflow-hidden">
                <div class="2xl:max-w-screen-lg xl:max-w-screen-lg lg:mx-auto mx-3">
                    <FordoSearchFeature />
                    <FordoEditFeature />
                    <FordoSaveFeature />
                    <NavigateFeatureButton/>
                </div>
            </div>
            <div className="bg-[#F8F9FC] overflow-hidden">
                <Footer/>
            </div>
        </>
    );
};

export default LandingPage;