import React from 'react';
import { Link } from 'react-router-dom';

const NavigateFeatureButton = (props) => {
    return (
        <div class="lg:py-20 py-10 flex justify-center wow fadeInDown">
            <Link to="/feature">
                <button class="shadow-md shadow-[#fccb6e] font-anekBangla text-lg font-semibold bg-[#FFA300] text-white px-4 py-3 rounded-full">সবগুলো ফিচার দেখুন</button>
            </Link>
        </div>
    );
};

export default NavigateFeatureButton;