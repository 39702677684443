import React from 'react';
import GooglePlay from '../../../assets/images/google_play.png'

const Footer = (props) => {
    return (
        <div class="lg:pt-20 pt-10 text-center">
            <h1 class="text-[28px] leading-normal lg:text-[2rem] text-[#08234A] lg:leading-normal font-semibold mt-5">ফর্দ অ্যাপটি ইনস্টল করুন গুগোল প্লে তে</h1>
            <p className="font-notoSans text-[#08234A] text-xl font-normal leading-normal">অ্যাপটি ডাউনলোড করতে এখানে <a className="underline" href="https://play.google.com/store/apps/details?id=com.scitechbd.fordo&hl=en&gl=US" target="_blank" rel="noopener noreferrer">ক্লিক করুন</a> অথবা নিচের ডাউনলোড বাটনে ক্লিক করুন</p>
            <div className="inline-block mt-5 mb-10">
                <a href="https://play.google.com/store/apps/details?id=com.scitechbd.fordo&hl=en&gl=US" target="_blank" rel="noopener noreferrer">
                    <img className="mx-auto" src={GooglePlay} alt="fordo-search-feature" />
                </a>
            </div>
            <hr />
            <p className="font-notoSans text-[#08234A] text-xl font-normal leading-normal my-6">&copy; ২০২২ সাইটেক বাংলাদেশ</p>
        </div>
    );
};

export default Footer;