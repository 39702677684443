import React from 'react';
import Edit from '../../../assets/images/edit.png'
import { editFeatureContent } from '../../../assets/constants/content'

const FordoEditFeature = (props) => {
    return (
        <div class="lg:pt-20 py-10">
            <div class="container flex flex-wrap lg:flex-nowrap justify-between items-center mx-auto">
                <div class="w-full">
                    <div className="bg-gradient-to-r from-[#D4E1FF] to-[#FFFFFF] rounded-full w-[4rem] h-[4rem] flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.722 34.722" className="fill-transparent h-[34.722px] w-[34.722px]">
                            <path d="M26,3.5a4.525,4.525,0,0,1,6.4,6.4l-21.6,21.6L2,33.894l2.4-8.8Z" transform="translate(-0.5 -0.672)" stroke="#2472e1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </svg>
                    </div>
                    <h1 class="text-[28px] leading-normal lg:text-[2rem] text-[#2472E1] lg:leading-normal font-semibold mt-5">{editFeatureContent?.title}</h1>
                    <ul class="text-[1.63rem] font-normal text-[#08234A] rounded-lg my-4 font-notoSans cursor-pointer">
                        {
                            editFeatureContent?.content?.map((content, index) =>
                                <li className="px-3 py-4 w-full flex items-start rounded-lg">
                                    <div className="border border-[#08234A] inline-block rounded-sm mr-3 mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-3 h-3 fill-[#08234A]">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p>{content}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div class="relative w-full wow slideInRight" data-wow-duration="2s">
                    <img src={Edit} class="z-10 relative" alt="fordo-search-feature" />
                    <div class="absolute top-[15%] left-0 -ml-[0.8rem] lg:-ml-[7rem] lg:top-[10%] lg:left-[10%] rounded-full bg-clip-content border-[1px] border-[#E5EFFF] lg:p-[2.8rem] p-8">
                        <div class="rounded-full bg-clip-content border-[1px] border-[#C7DDFF] lg:p-[2.8rem] p-8">
                            <div class="rounded-full bg-clip-content border-[1px] border-[#8AB9FF] lg:p-[11.2rem] p-[8rem]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FordoEditFeature;