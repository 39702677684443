import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppRoutes } from "../assets/constants/routes";
import FeaturePage from "./pages/FeaturePage";
import LandingPage from "./pages/LandingPage";
import WOW from 'wowjs';

function App(props) {
  useEffect(() => {
    new WOW.WOW({
      live: false
  }).init();
  }, [])
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={AppRoutes.DEFAULT} element={<LandingPage />} />
        <Route exact path={AppRoutes.FEATURE} element={<FeaturePage />} />
        <Route path='*' element={<Navigate to="/"/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
