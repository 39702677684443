import React from 'react';
import Save from '../../../assets/images/save.png'
import { saveFeatureContent } from '../../../assets/constants/content'

const FordoSaveFeature = (props) => {
    return (
        <div class="lg:pt-20 py-10">
            <div class="container flex flex-wrap flex-col-reverse lg:flex-row lg:flex-nowrap justify-between items-center mx-auto">
                <div class="relative w-full wow slideInLeft" data-wow-duration="2s">
                    <img src={Save} class="z-10 relative" alt="fordo-search-feature" />
                    <div class="absolute top-[15%] left-0 -ml-[0.8rem] lg:-ml-[7rem] lg:top-[10%] lg:left-[10%] rounded-full bg-clip-content border-[1px] border-[#E5EFFF] lg:p-[2.8rem] p-8">
                        <div class="rounded-full bg-clip-content border-[1px] border-[#C7DDFF] lg:p-[2.8rem] p-8">
                            <div class="rounded-full bg-clip-content border-[1px] border-[#8AB9FF] lg:p-[11.2rem] p-[8rem]">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div className="bg-gradient-to-r from-[#D4E1FF] to-[#FFFFFF] rounded-full w-[4rem] h-[4rem] flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.36 31.36" className="fill-transparent h-[31.36px] w-[31.36px]">
                            <g transform="translate(-281 -48)">
                                <path d="M8.711,3.049A5.669,5.669,0,0,0,3.049,8.711V22.649a5.669,5.669,0,0,0,5.662,5.662H22.649a5.669,5.669,0,0,0,5.662-5.662V8.711a5.669,5.669,0,0,0-5.662-5.662H8.711M8.711,0H22.649A8.711,8.711,0,0,1,31.36,8.711V22.649a8.711,8.711,0,0,1-8.711,8.711H8.711A8.711,8.711,0,0,1,0,22.649V8.711A8.711,8.711,0,0,1,8.711,0Z" transform="translate(281 48)" fill="#2472e1" />
                                <path d="M480.328,137.425a7.385,7.385,0,0,1-7.377-7.377v-.756a1.524,1.524,0,0,1,3.049,0v.756a4.329,4.329,0,0,0,8.657,0v-.756a1.524,1.524,0,1,1,3.049,0v.756A7.386,7.386,0,0,1,480.328,137.425Z" transform="translate(-183.893 -72.581)" fill="#2472e1" />
                            </g>
                        </svg>
                    </div>
                    <h1 class="text-[28px] leading-normal lg:text-[2rem] text-[#2472E1] lg:leading-normal font-semibold mt-5">{saveFeatureContent?.title}</h1>
                    <ul class="text-[1.63rem] font-normal text-[#08234A] rounded-lg my-4 font-notoSans cursor-pointer">
                        {
                            saveFeatureContent?.content?.map((content, index) =>
                                <li className="px-3 py-4 w-full flex items-start rounded-lg">
                                    <div className="border border-[#08234A] inline-block rounded-sm mr-3 mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-3 h-3 fill-[#08234A]">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p>{content}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default FordoSaveFeature;