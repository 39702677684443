import React from 'react';
import { Link } from 'react-router-dom';
import { navbarContent } from '../../../assets/constants/content';
import Logo from '../../../assets/images/logo.svg'

const Topbar = (props) => {
    return (
        <nav class="border-gray-200 px-2 sm:px-4 py-2.5">
            <div class="container flex flex-wrap justify-between items-center mx-auto">
                <Link to={'/'} class="flex items-center">
                    <img src={Logo} class="mr-3 h-11" alt="Fordo-Logo" />
                </Link>
                <div class="block w-auto">
                    <ul class="flex p-4 mt-4 flex-row md:space-x-8 md:mt-0 text-lg md:font-medium">
                        {
                            navbarContent?.menu?.map((menu, index) => 
                                <li>
                                    <Link to={menu.path} class={window?.location?.pathname === menu.path ? "block py-2 pr-4 pl-3 md:p-0 text-[#08234A] border-b-2 border-[#FFA300]" : "block py-2 pr-4 pl-3 text-slate-500 md:p-0"} aria-current="page">{menu.title}</Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Topbar;