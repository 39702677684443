export const navbarContent = {
    menu: [
        {
            title: "নীড় পাতা",
            path: "/"
        },
        {
            title: "ফিচার",
            path: "/feature"
        }

    ]
}

export const heroContent = {
    title: "নিত্যপণ্যের ফর্দ তৈরি ও ব্যবহার ফর্দ অ্যাপে",
    subTitle: "পণ্যের নাম ভুলে যাওয়া আর কাগজের ফর্দ বানিয়ে তা হারিয়ে ফেলার ঝামেলা থেকে মুক্তি মিলবে এবার"
}

export const searchFeatureContent = {
    title: "পণ্যের নাম বাংলায় অনুসন্ধান করুন",
    content:[
        "নিত্য প্রয়োজনীয় যেকোন পণ্য খুঁজে নিন টাইপ ও সার্চ করে",
        "পরিমাপের একক ও নামের সমন্বয়ে অনুসন্ধান করুন",
        "পরিমাণ ও নামের সমন্বয়ে অনুসন্ধান করুন",
        "সাজেশন থেকে প্রয়োজনীয় পণ্য ফর্দে যুক্ত করুন"
    ]
}

export const editFeatureContent = {
    title: "ফর্দে যুক্ত পণ্য এডিট করুন",
    content:[
        "পণ্যের নাম পরিবর্তন বা পরিমার্জন করুন",
        "পরিমাপের একক পরিবর্তন করুন",
        "পণ্যের পরিমাণ সমন্বয় করুন"
    ]
}

export const saveFeatureContent = {
    title: "বাজার করুন সংরক্ষিত ফর্দ নিয়ে",
    content:[
        "নতুন এবং পূর্বে বানিয়ে রাখা ফর্দের সাহায্যে বাজার করুন",
        "কেনা পণ্যগুলো টিক দিয়ে চিহ্নিত করুন",
        "ব্যবহৃত ফর্দ সম্পন্ন লিস্টে সংরক্ষণ করুন"
    ]
}