import React, { useState } from 'react';
import Search from '../../../assets/images/search.png'
import { searchFeatureContent } from '../../../assets/constants/content'

const FordoSearchFeature = (props) => {
    const [activeItem, setActiveItem] = useState(0);
    const activeItemHandler = (itemNo) => {
        if (typeof activeItem === 'number') {
            setActiveItem(itemNo)
        }
    }
    return (
        <div class="lg:pt-20 py-10">
            <div class="container flex flex-wrap flex-col-reverse lg:flex-row lg:flex-nowrap justify-between items-center mx-auto">
                <div class="relative w-full wow slideInLeft" data-wow-duration="2s">
                    <img src={Search} class="z-10 relative" alt="fordo-search-feature" />
                    <div class="absolute top-[15%] left-0 -ml-[0.8rem] lg:-ml-[7rem] lg:top-[10%] lg:left-[10%] rounded-full bg-clip-content border-[1px] border-[#E5EFFF] lg:p-[2.8rem] p-8">
                        <div class="rounded-full bg-clip-content border-[1px] border-[#C7DDFF] lg:p-[2.8rem] p-8">
                            <div class="rounded-full bg-clip-content border-[1px] border-[#8AB9FF] lg:p-[11.2rem] p-[8rem]">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div className="bg-gradient-to-r from-[#D4E1FF] to-[#FFFFFF] rounded-full w-[4rem] h-[4rem] flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-[2.8rem] w-[2.8rem] stroke-[#2472E1] fill-transparent">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                    <h1 class="text-[28px] leading-normal lg:text-[2rem] text-[#2472E1] lg:leading-normal font-semibold mt-5">{searchFeatureContent?.title}</h1>
                    <ul class="text-[1.63rem] font-normal text-[#08234A] rounded-lg my-4 font-notoSans cursor-pointer">
                        {
                            searchFeatureContent?.content?.map((content,index) =>
                                <li className={activeItem === index ? "px-3 py-4 w-full flex items-start rounded-lg bg-[#EEF6FF]" : "px-3 py-4 w-full flex items-start rounded-lg"} onClick={()=>activeItemHandler(index)}>
                                    <div className={activeItem === index ? "border border-[#2472E1] inline-block rounded-sm mr-3 mt-2":"border border-[#08234A] inline-block rounded-sm mr-3 mt-2"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class={activeItem === index ? "w-3 h-3 fill-[#2472E1]": "w-3 h-3 fill-[#08234A]"}>
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p className={activeItem === index && "text-[#2472E1]"}>{content}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default FordoSearchFeature;