import React,{useState} from 'react';
import { heroContent } from '../../../assets/constants/content';
import Hero from '../../../assets/images/hero.png'
import VideoAdModal from '../modal/VideoAdModal';

const HeroSection = (props) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
        {
            showModal&&
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30"></div>
        }
        <div class="lg:py-20 py-10">
            <div class="container flex flex-wrap lg:flex-nowrap justify-between items-center mx-auto">
                <div class="w-full 2xl:pr-[10rem]">
                    <h1 class="text-4xl leading-normal lg:text-5xl text-[#08234A] lg:leading-normal font-semibold">{heroContent?.title}</h1>
                    <p class="text-xl font-normal leading-normal text-[#68768A] font-notoSans my-6">{heroContent?.subTitle}</p>
                    <div class="flex items-center space-x-8">
                        <a href="https://play.google.com/store/apps/details?id=com.scitechbd.fordo&hl=en&gl=US" target="_blank" rel="noopener noreferrer">
                            <button class="hover:shadow-lg hover:shadow-blue-100 font-anekBangla text-lg font-semibold bg-[#2472E1] text-white px-4 py-3 border rounded-full">
                                অ্যাপ ডাউনলোড<span class="text-sm pl-2 font-bold">&rang;</span>
                            </button>
                        </a>
                        <button onClick={()=>setShowModal(true)} class="group font-anekBangla text-lg font-normal flex items-center space-x-2">
                            <span class="rounded-full px-3 py-3 group-hover:shadow-lg group-hover:shadow-orange-300 border-2 border-[#FFA300] group-hover:bg-[#FFA300] ease-out duration-500 transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={2.5} class="w-8 h-8 fill-[#FFA300] group-hover:fill-white stroke-[#FFA300] group-hover:stroke-white transition-colors duration-300 ease-in-out wow flipInY" data-wow-duration="3s">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                </svg>
                            </span>
                            <span>দেখুন যেভাবে কাজ করে</span>
                        </button>
                    </div>
                </div>
                <div class="relative w-full wow slideInRight" data-wow-duration="2s">
                    <img src={Hero} class="z-10 relative" alt="fordo-hero" /> 
                    <div class="absolute -mr-20 top-2 right-4 w-[22rem] h-[22rem] bg-[#CFD6FF] rounded-full"></div>
                </div>
            </div>
        </div>
         {
            showModal&&
            <VideoAdModal setShowModal={setShowModal}/>
        }
        </>
    );
};

export default HeroSection;