import React from 'react';
import Topbar from '../components/navigation/Topbar';

const FeaturePage = (props) => {
    return (
        <>
            <div class="bg-[#F9FBFF] overflow-hidden">
                <div class="2xl:max-w-screen-xl xl:max-w-screen-lg lg:mx-auto mx-3">
                    <Topbar />
                </div>
            </div>
            <div class="overflow-hidden mt-10 text-2xl">
               <h1 className="text-center">শীঘ্রই আসছে</h1>
            </div>
        </>
    );
};

export default FeaturePage;